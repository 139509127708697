@use "sass:math";

@import "../../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/email-results/email-results";

.email-result{
    &__page{
        background-color: $brand-color-24;
        margin-left: -$base-margin*0.5;
        margin-right: -$base-margin*0.5;
        padding: $base-padding*0.5;
        &_header{
            display: flex;
            background-color: $brand-color-2;
            padding: math.div($base-padding, 6);
            .modal{
                &__header{
                    &_close{
                        color: $brand-color-24;
                    }
                }
            }
        }
        &__header{
            &_text{
                color: $brand-color-24;
                display: block;
                width: 95%;
                h2{
                    margin-bottom: 0;
                    line-height: 1;
                }
            }
        }
        input[type="email"], input[type="text"]{
            border-color: $brand-color-23;
            border-radius: math.div($panel-border-radius, 3);
            padding: 0.5rem 0.5rem;
            line-height: 10;
            color: $brand-color-30;
        }
        .tagging-container,textarea{
            border: $border-width solid $brand-color-23;
            border-radius: math.div($panel-border-radius, 3);
            padding-left: 0.3rem;
            color: saturate($brand-color-26,30) ;
        }
        textarea{
            padding-left: 0.7rem;
            border-color: $brand-color-23;
            color: $brand-color-30;
        }
        &__control-buttons{
            .control-buttons{
                &__send-email{
                    border-radius: math.div($panel-border-radius, 3);
                }
            }
        }
    }
}