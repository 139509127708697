@use "sass:math";

@import "../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/multi-select";

.multi-select{
    .caret {
        border-top: 0.3125rem dashed;
        border-right: 0.3125rem solid transparent;
        border-left: 0.3125rem solid transparent;
        left: 95%;
        top: 42%;
    }
    .dropdown-header, .dropdown-menu > li > a{
        padding: math.div($base-padding, 6) math.div($base-padding, 3);
        color: saturate($brand-color-26, 30);
    }
    .multi-select-field{
        font-weight: 600;
        font-size: 0.800rem;
    }
}