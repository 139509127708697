@import "../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/airline-selector";

.airline-selector {
	&__wrapper {
	    .multi-select{
		   .multi-airline-select {
   				line-height: 1;
				&__name {
					color: $brand-color-30;
                    font-size: 0.8rem;
				}
				&__image {
					margin-top: 0;
    			}
			}
		}
	}
}