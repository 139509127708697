@use "sass:math";

@import "../../../../../../stylesheets/modules_new/styles/components/air/flight-review/flight-review-row";


.flight__review {
  &_main {
    padding-top: $base-padding;
  }
  &_card{
    .header{
      &_legs {
        font-size:0.8125rem;
      }
      &_text{
        padding-left: 1.0rem;
      }
    }
    &_header {
      color: $brand-color-1;
    }
   
    .baggage-details{
      &__header{
          margin-top: $base-margin*0.5;
          background-color: $brand-color-2;
          border-top-left-radius: $panel-border-radius;
          color: $brand-color-24;
          &_baggage-type,&_pax-type{
            padding: math.div($base-padding, 6) math.div($base-padding, 3);
            font-weight: 700;
          }
        }
    }
    .flight-details__tab-content{
      &_baggage-details{
        background-color: transparent;
        .alert{
          &__message--warning{
            background-color: $brand-color-2;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: $panel-border-radius;
            color: $brand-color-24;
          }
        }
      }
    }
    .baggage-details{
      &__row{
        border-top: 0;
        border-bottom: $border-width solid lighten($brand-color-25, 20);
        &:last-child{
          border-top: 0;
        }
        &:first-child{
          border-bottom: 0;
        }
      }
    }
  }
}

.details__details {
    &_layover,
    &_return {
      &:after {
        position: absolute;
        width: 55.5%;
        top: -0.1875rem;
        margin-left: -4.5%;
        content: "";
        @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
          margin-left: -5.2%;
          width: 56%;
          top: -0.46875rem;
        }
        @media screen and (max-width: $sm-screen-size) {
          margin-left: -6.2%;
          width: 57%;
        }
      }
    }
    &_layover:after {
      background-color: $brand-color-1;
    }
    
}