@use "sass:math";

@import "../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/alerts";

.alert__message{
    &--error {
        background-color: lighten($brand-color-2,65);
        padding: math.div($base-padding, 6);
        border-radius: math.div($panel-border-radius, 6);
        width: 100%;
        display: inline-block;
        height: 1.875rem;
        .close{
            top: -0.9375rem;
        }
    }
    &--info{
        background-color: $brand-color-27;
    }
}