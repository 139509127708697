@use "sass:math";

@import "../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/full-screen-modals";

.full-screen-modal {
    &__header {
        &_heading {
            border-top-left-radius: $panel-border-radius;
            height: 2rem;
            padding: 0.2rem 1rem;
        }
        &_client-logo{
            width: 100%;
            text-align: center;
            margin-top: math.div($base-margin, 6);
            margin-bottom: $base-margin*0.1;
            > img{
                height: 3.75rem;
            }
        }
    }
}