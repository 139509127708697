@use "sass:math";

@import "../../../../../../stylesheets/modules_new/styles/components/air/flight-review/tax-breakup";

.tax-breakup {
    &__table{
        margin-left: -$base-padding;
        margin-right: -$base-padding;
        margin-top: math.div($base-padding, 3);
        > table{
            > thead{
                background-color: $brand-color-2;
                th{
                    color: $brand-color-24;
                    font-weight: 700;
                    text-align: center;
                    &:first-child{
                        border-top-left-radius: $panel-border-radius;
                        padding-left: $base-padding;
                    }
                }
            }
            > tbody{
                tr{
                    border-bottom: $border-width solid lighten($brand-color-25, 20);
                    border-top: $border-width solid lighten($brand-color-25, 20);
                    &:last-child{
                        background-color: $brand-color-24;
                        td{
                            color: $brand-color-2;
                            font-weight: 700;
                            text-align: center;
                            &:first-child{
                                color: $brand-color-23;
                                border-top-left-radius: $panel-border-radius;
                                padding-left: $base-padding;
                            }
                        }
                    }
                    td{
                        text-align: center;
                        &:first-child{
                            color: $brand-color-23;
                            padding-left: $base-padding;
                        }
                    }
                    &:last-child, &:nth-last-child(2), &:first-child{
                        border: 0;
                    }
                }
            }
        }
    }
}

.review-fare-details{
    &_card{
        &_header{
            padding-left: $base-padding*0.5;
            background-color: $brand-color-24;
        }
    }
    &__table{
        table{
            thead{
                background-color: $brand-color-2;
                color: $brand-color-24;
                position: relative;
                
            }
            tbody {
                tr {
                  td {
                    &:first-child {
                      color: $brand-color-23;
                    }
                  }
                }
              }
        }
    }
}