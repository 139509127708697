@import "../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/action-modal";

.action-modal-container{
    &__action-button-container{
        &_approve-button,&_reject-button{
            background-color: $brand-color-31;
            color: $brand-color-24;
            border-radius: $panel-border-radius*0.2;
            &:hover, &:focus{
                background-color: $brand-color-1;
                color: $brand-color-24;
            }
        }
    }
}