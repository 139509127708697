@use "sass:math";

@import "../../../../../../../stylesheets/modules_new/styles/components/commons/components/shared-components/modals";

.modal{
    &__header{
        &_heading{
            font-size: 1.2rem;
        }
        &_info{
            background-color: $brand-color-2;
            border-top-left-radius: $panel-border-radius;
         }
    }
    &__content{
        .note-box{
            font-size: 0.82rem;
        }
    }
}
.header__info {
    &_part {
        color: $brand-color-24;
        padding-left: $base-padding*0.5;
        line-height: 1.6;
        font-size: 1rem;
    }
    &_part2{
        margin-top: 0.125rem;
        color: $brand-color-24;
    }
    &_subpart2{
        background-color: transparent;
        border-left: $border-width solid $brand-color-24;
        padding: $base-padding*0.1 $base-padding*0.5;
    }
    &_subpart1{
        span{
            margin-left: math.div($base-margin, 6);
        }
    }
}