@import "../../../../../stylesheets/modules_new/styles/components/mixins/review-page-nav";

@mixin  create-review-nav{ 
    @include create-review-nav_private;
    
    .nav {
        background-color: $brand-color-2;
        border-top-left-radius: $panel-border-radius;
    }
    .nav-tabs > li > a {
        color: $brand-color-24;
    }

    .nav-tabs > li {
        border-right: $border-width solid $brand-color-24;
        margin-bottom: 0;
    }

    .nav > li > a:hover,
    .nav > li > a:focus {
      background-color: $brand-color-1;
      border-top-left-radius: $panel-border-radius;
    }

    .nav > li > a{
        padding: 0.375rem $base-padding*0.5;
    }
    
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
      color: $brand-color-24;
      background-color: $brand-color-1;
      border-top-left-radius: $panel-border-radius;
      padding: 0.375rem $base-padding*0.5;
      font-size: 0.875rem;
    }
}